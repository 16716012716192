<template>
  <app-list :list="queue" header="workQueue.title">
    <template #icon="{ item }">
      <icon-check v-if="item.isDone" />
      <icon-dot v-else />
    </template>
    <template #overline="{ item }">
      {{ item.debtor.name }}
    </template>
    <template #title="{ item }">
      <div :class="{ 'list-title--text': !item.showError && !item.showWarning }">
        {{ item.title }}
      </div>
    </template>
    <template #subtitle="{ item }">
      {{ getCustomNumbers(item.vouchers) }}
    </template>
    <template #date="{ item }">
      <div class="flex-fill">{{ item.updated | formatDateTime }}</div>
    </template>

    <template v-if="isMobile" #details>
      <slot name="details"></slot>
    </template>
  </app-list>
</template>

<script>
import getCustomNumbersFromVoucherList from '@/helper/getCustomNumbersFromVoucherList';

export default {
  name: 'QueueList',

  props: {
    queue: {
      type: Array,
      required: true
    }
  },

  methods: {
    getCustomNumbers(vouchers) {
      return getCustomNumbersFromVoucherList(vouchers);
    }
  }
};
</script>
