/**
 * @see {@link https://wiki.move-elevator.de/display/DVKMU/Inkasso-Forderungstypen+-+Wertedefinition}
 */
export const EXPOSURE_TYPES = [
  { code: '092', translation: 'Dienstleistungsvertrag' },
  { code: '099', translation: 'Handwerkerleistung' },
  { code: '095', translation: 'Warenlieferung' },
  { code: '100', translation: 'Reparaturleistung' },
  { code: '094', translation: 'Kaufvertrag' },
  { code: '096', translation: 'Mietvertrag Sachgüter (nicht KFZ, Immobilien)' },
  { code: '109', translation: 'Versorgungsleistung / Energielieferung' },
  { code: '093', translation: 'Geschäftsbesorgung' },
  { code: '097', translation: 'Schadensersatz aus Mietvertrag' },
  { code: '098', translation: 'Werkvertrag / Werklieferungsvertrag' }
];

export default EXPOSURE_TYPES;
