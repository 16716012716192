<template>
  <app-dialog
    v-model="open"
    :loading="loading"
    primary-action-text="workQueue.submit"
    secondary-action-text="navigation.cancel"
    title="workQueue.checkAnswer"
    @submit="submit"
  >
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
      <slot :name="name" v-bind="slotData"></slot>
    </template>

    <message-box :title="request.title" :text="request.text" />

    <v-row v-if="request.answer" dense class="ma-4">
      <v-col cols="12">
        {{ $t('workQueue.yourAnswer') }}
      </v-col>
      <v-col cols="12" class="semi-bold">
        {{ request.answer }}
      </v-col>
    </v-row>

    <v-row v-if="request.attachment" dense class="ma-4">
      <v-col cols="12">
        {{ $t('attachment') }}
      </v-col>
      <v-col class="semi-bold">
        {{ request.attachment.name }}
      </v-col>
    </v-row>

    <v-row v-if="request.message" dense class="ma-4">
      <v-col cols="12">
        {{ $t('workQueue.message') }}
      </v-col>
      <v-col class="semi-bold">
        {{ request.message }}
      </v-col>
    </v-row>
  </app-dialog>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import MessageBox from '@/modules/User/components/WorkQueue/MessageBox';

export default {
  name: 'ConfirmSubmitDialog',

  mixins: [ShowDialogMixin],

  components: { MessageBox },

  props: {
    formData: {
      type: Object,
      required: true
    },
    request: {
      type: Object,
      required: true
    }
  },

  methods: {
    submit() {
      this.$emit('submit');
    }
  }
};
</script>
