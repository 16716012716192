<template>
  <div>
    <div v-if="date" class="grey--text caption mb-2">
      {{ date | formatDate }}
    </div>
    <div class="message__box">
      <div v-if="checked" class="message__icon">
        <icon-check color="white" />
      </div>
      <div class="flex-grow-1">
        <div v-if="title" class="mb-4 semi-bold">
          {{ title }}
        </div>
        <div v-if="subTitle" class="mb-4 grey--text subtitle-1">
          {{ subTitle }}
        </div>
        <div>
          {{ text }}
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MessageBox',

  props: {
    checked: {
      type: Boolean,
      default: false
    },
    date: {
      type: [String, Number],
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
.message__box {
  background-color: var(--c-grey-background);
  padding: 20px;
  width: 100%;
  display: flex;
  white-space: pre-line;
}

.message__icon {
  background-color: var(--c-primary);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  flex: 0 0 auto;
}
</style>
