<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-model="open"
      :primary-action-text="getPrimaryActionText('navigation.takeSelected')"
      secondary-action-text="navigation.cancel"
      title="vouchers.exposureType.title"
      text="vouchers.exposureType.info"
      :disabled="failed || !exposureType"
      :loading="loading"
      @submit="submit"
    >
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData"></slot>
      </template>

      <v-container class="px-0">
        <v-row dense class="mt-4 semi-bold">
          <v-col cols="12" sm="4">{{ $t('debtor') }}:</v-col>
          <v-col cols="12" sm="8">{{ debtor.name }}</v-col>
        </v-row>
        <v-row dense class="mt-6 semi-bold" justify="end">
          <v-col cols="12" sm="4">{{ $t('invoice') }}:</v-col>
          <v-col cols="12" sm="8">
            {{ voucher.numberToShow }}
            <div class="font-weight-regular">
              {{ voucher.leftoverAmount | formatCurrency }} {{ $t('vouchers.outstanding') }}
              <div class="text--capitalized">{{ getCountedDueDays(voucher) }}</div>
            </div>
          </v-col>
        </v-row>

        <v-row align="center" class="mt-6">
          <v-col cols="12" class="text-right">
            <app-select
              v-model="debtorType"
              :items="debtorTypeItems"
              :label="$t('vouchers.exposureType.selectDebtorType')"
              required
            />
          </v-col>
        </v-row>

        <v-row align="center">
          <v-col cols="12" class="text-right">
            <app-select
              v-model="exposureType"
              :items="exposureTypeItems"
              :label="$t('vouchers.exposureType.selectExposureType')"
              required
            />
            <app-checkbox
              v-if="exposureType && exposureType !== defaultExposureType"
              v-model="setExposureTypeAsDefault"
              label="vouchers.exposureType.setExposureTypeAsDefault"
            />
          </v-col>
        </v-row>
      </v-container>
    </app-dialog>
  </ValidationObserver>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import WorkQueueFollowUpMixin from '@/mixins/WorkQueueFollowUpMixin';
import PaymentDeadlineMixin from '@/mixins/PaymentDeadlineMixin';
import ExposureTypeMixin from '@/mixins/ExposureTypeMixin';

export default {
  name: 'ExposureTypeDialog',

  mixins: [ShowDialogMixin, PaymentDeadlineMixin, WorkQueueFollowUpMixin, ExposureTypeMixin],

  props: {
    debtor: {
      type: Object,
      required: true
    },
    voucher: {
      type: Object,
      required: true
    }
  },

  methods: {
    async submit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      await this.submitDefaultExposureType();

      const data = {
        debtorType: this.debtorType,
        exposureType: this.exposureType
      };

      if (this.emitData) {
        this.$emit('submit', data);
      }
    }
  },

  async created() {
    await this.getDefaultExposureType();
    this.debtorType = this.debtor.type;

    if (this.prefillData) {
      this.exposureType = this.prefillData.exposureType;
      this.debtorType = this.prefillData.debtorType;
    }
  }
};
</script>
