<template>
  <v-card flat class="d-flex flex-column fill-height flex-nowrap">
    <v-card-title class="caption">
      <app-link-btn :title="details.debtor.name" :to="getDebtorRoute(details.debtor)" />
    </v-card-title>
    <v-divider />

    <v-card-text class="pa-0">
      <app-alert
        :value="details.isSent"
        type="warning"
        icon="$iconHourglass"
        text="workQueue.isSent"
        class="mb-8"
      />

      <app-alert
        :value="details.isDone"
        type="success"
        icon="$iconCheck"
        text="workQueue.isDone"
        class="mb-8"
      />

      <div class="ma-4">
        <div v-if="hasAdminView" class="caption grey--text semi-bold">
          {{ $t('workQueue.transactionKey') }}:
          {{ details.transactionKey }}
        </div>
        <h3 class="mb-4">{{ details.title }}</h3>
        <v-row
          v-for="(voucher, index) in details.vouchers"
          :key="voucher.id"
          dense
          justify="space-between"
          class="subtitle-1"
        >
          <v-col cols="12" md="5">
            <div>
              <span class="mr-1">{{ voucher.type | formatVoucherType }}</span>
              <app-text-btn
                :title="voucher.numberToShow || '-'"
                :to="getVoucherRoute(voucher)"
                underlined
              />
            </div>
          </v-col>
          <v-col cols="12" md="7">
            <div>
              {{ $t('vouchers.invoiceAmount') }}:
              {{ voucher.amount | formatCurrency }}
            </div>
            <div>
              {{ $t('vouchers.outstandingAmount') }}:
              {{ voucher.leftoverAmount | formatCurrency }}
            </div>
            <div class="text--capitalized">{{ getCountedDueDays(voucher) }}</div>
          </v-col>
          <v-row v-if="isMobile" class="mb-2">
            <v-col>
              <file-link-btn
                highlighted
                prepend-icon
                title="navigation.view"
                @click="$router.push({ query: { ...$route.query, preview: index } })"
              />
            </v-col>
          </v-row>
        </v-row>
      </div>
    </v-card-text>
    <v-divider />

    <template v-if="details.documentSource">
      <v-row no-gutters class="flex-auto">
        <v-col>
          <file-link-btn
            :class="{ 'ma-4': true, 'mx-3': isMobile }"
            :loading="loadingFileType === 'document'"
            highlighted
            prepend-icon
            title="workQueue.furtherDocumentsToFile"
            @click="openFile(details.documentSource, 'document', $t('pdf'))"
          />
        </v-col>
      </v-row>

      <v-divider />
    </template>

    <v-card-actions class="flex-column align-start pa-0">
      <app-alert class="my-4" :value="details.hasError">
        {{ $t('workQueue.submitError') }}
      </app-alert>

      <task-form
        v-if="details.isOpen && permissions.workQueue.answer"
        :details="details"
        :class="{ 'full-width': true, 'mt-4': !isMobile }"
        @success="$emit('refresh')"
      />

      <message-box
        v-else
        :checked="!details.isOpen"
        :class="{ 'full-width': true, 'mt-4': !isMobile }"
        :text="details.text"
        :title="details.title"
      >
        <div v-if="details.counterVouchers.length > 0" class="mt-8">
          {{ $tc('workQueue.counterVouchersCounted', details.counterVouchers.length) }}:
          <template v-for="(voucher, index) in details.counterVouchers">
            <app-text-btn
              :key="voucher.id"
              :to="getVoucherRoute(voucher)"
              class="semi-bold"
              underlined
            >
              {{ voucher.type | formatVoucherType }}
              {{ voucher.numberToShow }}
            </app-text-btn>
            <template v-if="index + 1 < details.counterVouchers.length">{{ ', ' }}</template>
          </template>
        </div>

        <div v-if="details.attachmentSource" class="mt-8">
          {{ $t('workQueue.uploadedAttachment') }}:
          <file-link-btn
            :loading="loadingFileType === 'attachment'"
            append-icon
            class="semi-bold"
            title="attachment"
            @click="openFile(details.attachmentSource, 'attachment')"
          />
        </div>
      </message-box>

      <template v-for="(message, index) in details.messages">
        <message-box
          :key="index"
          :class="{ 'mb-6': true, 'mx-4': isMobile, 'mt-6': index === 0 }"
          :text="message.text"
          :sub-title="
            message.sentFromCreditor
              ? $t('workQueue.messageFromCreditor')
              : $t('workQueue.messageFromSap')
          "
          :date="message.created"
        />
      </template>
    </v-card-actions>
  </v-card>
</template>

<script>
import FileLinkBtn from '@/shared/components/FileLinkBtn';
import MessageBox from '@/modules/User/components/WorkQueue/MessageBox';
import OpenFileMixin from '@/mixins/OpenFileMixin';
import PaymentDeadlineMixin from '@/mixins/PaymentDeadlineMixin';
import TaskForm from '@/modules/User/components/WorkQueue/TaskForm';

export default {
  name: 'DetailedEntry',

  components: {
    FileLinkBtn,
    MessageBox,
    TaskForm
  },

  mixins: [PaymentDeadlineMixin, OpenFileMixin],

  props: {
    details: {
      type: Object,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  padding: 0 1rem 0 0.5rem;
}

.v-application.mobile .v-card {
  padding: 0;
}

.v-card__title {
  height: 3.5rem;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
}

hr.v-divider {
  border-color: var(--c-border);
}

.attachment-link__icon {
  vertical-align: middle;
  margin-left: 4px;
}
</style>
