<template>
  <app-filled-view-container @close="redirectToLastRoute">
    <template #actions>
      <copy-report-btn v-if="!!detailedEntry" :custom-data="reportData" />
    </template>

    <template #list>
      <work-queue-list :queue="workQueue">
        <template #details>
          <div v-if="loadingDetails || !detailedEntry" class="d-flex justify-center py-12">
            <app-spinner />
          </div>
          <template v-else>
            <detailed-entry
              :key="detailedEntry.updated"
              :details="detailedEntry"
              @refresh="updateDetailedEntry"
            />
          </template>
        </template>
      </work-queue-list>
    </template>

    <template v-if="detailedEntry" #details>
      <detailed-entry
        :key="detailedEntry.updated"
        :details="detailedEntry"
        @refresh="updateDetailedEntry"
      />
    </template>

    <template v-if="detailedEntry" #preview>
      <voucher-list-pdf-preview v-if="detailedEntry.vouchers.length > 0" :details="detailedEntry" />
      <p v-else class="align-self-center">
        {{ $t('workQueue.previewNotAvailable') }}
      </p>
    </template>

    <template v-if="workQueue.length === 0 || loadingDetails" #content>
      <span v-if="loadingDetails">
        <app-spinner />
      </span>
      <span v-else class="text-center">
        <img :src="require('@/modules/User/assets/EmptyWorkQueueList.svg')" />
        <h3 class="mt-6">{{ $t('workQueue.noDataTitle') }}</h3>
        <p class="mt-3">{{ $t('workQueue.noDataText') }}</p>
      </span>
    </template>

    <template v-if="workQueueError || !initializedList" #container>
      <v-col cols="12" class="d-flex justify-center align-center">
        <app-error-alert v-if="workQueueError" />
        <app-spinner v-else />
      </v-col>
    </template>

    <template v-if="!loadingDetails && detailedEntry" #dialogs>
      <voucher-preview-dialog
        v-if="isMobile"
        :voucher="detailedEntry.vouchers[showVoucherIndex]"
        :value="showVoucherIndex !== undefined"
        @input="showVoucherIndex = undefined"
      />
    </template>
  </app-filled-view-container>
</template>

<script>
import CopyReportBtn from '@/shared/components/CopyReportBtn';
import DetailedEntry from '@/modules/User/components/WorkQueue/DetailedEntry';
import RedirectMixin from '@/mixins/RedirectMixin';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import VoucherListPdfPreview from '@/modules/User/components/WorkQueue/VoucherListPdfPreview';
import VoucherPreviewDialog from '@/shared/components/VoucherPreviewDialog';
import WorkQueueList from '@/modules/User/components/WorkQueue/WorkQueueList';
import { NAMESPACE } from '@/modules/User/store';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'WorkQueue',

  components: {
    CopyReportBtn,
    DetailedEntry,
    VoucherListPdfPreview,
    VoucherPreviewDialog,
    WorkQueueList
  },

  mixins: [ShowDialogMixin, RedirectMixin],

  data: () => ({
    lastRoutePath: '',
    detailedEntry: null,
    initializedList: false,
    loadingDetails: false
  }),

  computed: {
    ...mapState(NAMESPACE, {
      workQueue: 'workQueue',
      loadingList: 'loadingWorkQueue',
      workQueueError: 'workQueueError'
    }),
    showVoucherIndex: {
      get() {
        return this.getQueryParam(this.queryParam.PREVIEW);
      },
      set(value) {
        this.setQueryParam(this.queryParam.PREVIEW, value);
      }
    },
    reportData() {
      if (!this.detailedEntry) {
        return;
      }

      return (
        `Sachstand:
        * Identifier: ${this.detailedEntry.id}
        * Sachstandsnr.: ${this.detailedEntry.code}
        * Akte: ${this.detailedEntry.transactionKey}
        * Akten-Identifier: ${this.detailedEntry.fileId}

        Kunde:
        * Identifier: ${this.detailedEntry.debtor.id}
        * Name: ${this.detailedEntry.debtor.name}

        ` +
        this.detailedEntry.vouchers
          .map(
            (voucher, index, vouchers) =>
              `Beleg${vouchers.length > 1 ? ' ' + (index + 1) : ''}:
            * Identifier: ${voucher.id}
            * OBN: ${voucher.officialVoucherNumber}`
          )
          .join('\n')
      );
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchWorkQueue']),

    async updateDetailedEntry() {
      if (
        this.$route.name !== this.routeName.WORK_QUEUE ||
        (!this.$route.params.id && this.isMobile)
      ) {
        return;
      }

      if (this.workQueue.length === 0 && !this.$route.params.id) {
        return;
      }

      this.loadingDetails = true;

      if (!this.$route.params.id) {
        this.$router.replace({
          params: { id: this.workQueue[0].id },
          query: { ...this.$route.query }
        });
      }

      this.detailedEntry = this.workQueue.find((entry) => entry.id === this.$route.params.id);

      if (!this.detailedEntry) {
        this.$router.replace({
          params: { id: this.workQueue.length > 0 ? this.workQueue[0].id : undefined },
          query: { ...this.$route.query }
        });
      }

      this.loadingDetails = false;
    }
  },

  watch: {
    isMobile() {
      if (!this.isMobile && this.showVoucherIndex !== undefined) {
        this.showVoucherIndex = undefined;
      }

      if (!this.isMobile && !this.$route.params.id) {
        this.updateDetailedEntry();
      }
    },
    '$route.params.id'() {
      this.updateDetailedEntry();
    }
  },

  async created() {
    await this.fetchWorkQueue();
    await this.updateDetailedEntry();
    this.initializedList = true;
  }
};
</script>

<style lang="scss" scoped>
.v-card {
  display: flex;
  flex-direction: column;
  border-radius: inherit;
  overflow-x: hidden;
  max-width: unset;
}
</style>
