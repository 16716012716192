<template>
  <ValidationObserver ref="form" tag="form">
    <template v-if="!error">
      <message-box :title="request.title" :text="request.text" class="task-form">
        <app-radio-group
          v-model="selectedAction"
          :items="radioOptions"
          required
          :row="false"
          class="mt-4 mb-8 align-center d-flex"
        />
        <app-file-input
          v-model="attachment"
          :required="selectedAction.fileRequired"
          append-icon
          class="d-block mt-8"
          label="workQueue.addAttachment"
          name="attachment"
          rules="ext:pdf"
        />
        <app-textarea
          v-model="message"
          :required="selectedAction.noteRequired"
          class="d-block mt-8"
          label="workQueue.addMessage"
          name="workQueue.message"
          rows="2"
        />
        <v-row no-gutters justify="end" class="mt-8">
          <v-col cols="auto">
            <app-btn :disabled="!selectedAction.code" @click="setFollowUpComponent">
              {{ $t('navigation.continue') }}
            </app-btn>
          </v-col>
        </v-row>

        <component
          :is="followUpComponent"
          :key="details.vouchers[currentVoucherIndex].id"
          ref="followUpComponent"
          v-model="showFollowUpDialog"
          v-bind="followUpProps"
          :loading="loading"
          :voucher="details.vouchers[currentVoucherIndex]"
          :request="request"
          :form-data="{ selectedAction, message }"
          :prefill-data="followUpPayload[details.vouchers[currentVoucherIndex].id]"
          :primary-action-text="
            showsConfirmDialog
              ? 'navigation.send'
              : requiresMultipleFollowUps
              ? 'navigation.continue'
              : ''
          "
          :total-leftover-only="selectedAction.fullAmount"
          emit-data
          @submit="addFollowUpPayload($event)"
        >
          <template #prepend>
            <app-alert class="subtitle-1" color="primary" icon="$iconInfo" :colored-border="false">
              {{ followUpMessage }}
              <template v-if="requiresMultipleFollowUps && !showsConfirmDialog">
                <div class="semi-bold">
                  {{ $t('workQueue.followUpInfoSend') }}
                  ({{ $t('voucher') }} {{ currentVoucherIndex + 1 }} {{ $t('of') }}
                  {{ details.vouchers.length }})
                </div>
              </template>
            </app-alert>
          </template>

          <template #secondaryAction>
            <app-btn block outlined @click="cancel">
              {{ currentVoucherIndex === 0 ? $t('navigation.cancel') : $t('navigation.goBack') }}
            </app-btn>
          </template>
        </component>

        <app-dialog
          v-model="submitted"
          secondary-action-text="navigation.ok"
          title="workQueue.confirmed"
          text="workQueue.decisionReceived"
          @close="hideAfterSuccess"
        />
      </message-box>
    </template>

    <template v-else>
      <app-alert class="mt-4" />
    </template>
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/User/store';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import WorkQueueFollowUpMixin from '@/mixins/WorkQueueFollowUpMixin';
import MessageBox from '@/modules/User/components/WorkQueue/MessageBox';
import ConfirmSubmitDialog from '@/modules/User/components/WorkQueue/ConfirmSubmitDialog';
import CreditDialog from '@/modules/Vouchers/components/Actions/CreditDialog';
import DirectPaymentDialog from '@/modules/Vouchers/components/Actions/DirectPaymentDialog';
import AbandonmentDialog from '@/modules/Vouchers/components/Actions/AbandonmentDialog';
import ExposureTypeDialog from '@/modules/Vouchers/components/Actions/ExposureTypeDialog';
import AnnulationDialog from '@/modules/Vouchers/components/Actions/AnnulationDialog';
import SubsequentVoucherUploadDialog from '@/modules/Vouchers/components/Actions/SubsequentVoucherUploadDialog';
import FollowUpAction from '@/modules/User/statics/followUpAction';

export default {
  name: 'TaskForm',

  mixins: [ShowDialogMixin, WorkQueueFollowUpMixin],

  components: {
    MessageBox
  },

  props: {
    details: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    selectedAction: {},
    message: '',
    attachment: null,
    followUpComponent: null,
    followUpPayload: {},
    followUpProps: {},
    currentVoucherIndex: 0,
    error: false
  }),

  computed: {
    request() {
      return {
        title: this.details.title,
        text: this.details.text,
        answer: this.selectedAction.text,
        message: this.message,
        attachment: this.attachment
      };
    },
    radioOptions() {
      const actions = [];

      if (this.details.accept.code !== '0' && !!this.details.accept.text) {
        actions.push({
          value: this.details.accept,
          label: this.details.accept.text
        });
      }

      if (this.details.reject.code !== '0' && !!this.details.reject.text) {
        actions.push({
          value: this.details.reject,
          label: this.details.reject.text
        });
      }

      return actions;
    },
    showFollowUpDialog: {
      get() {
        return this.followUpComponent !== null;
      },
      set() {
        this.followUpComponent = null;
      }
    },
    showsConfirmDialog() {
      return this.followUpComponent === ConfirmSubmitDialog;
    },
    followUpMessage() {
      if (this.showsConfirmDialog) {
        return this.$t(`workQueue.followUpInfo.default`);
      }

      return this.$te(`workQueue.followUpInfo.${this.selectedAction.followUp}`)
        ? this.$tc(
            `workQueue.followUpInfo.${this.selectedAction.followUp}`,
            this.details.vouchers.length
          )
        : this.$t(`workQueue.followUpInfo.default`);
    },
    requiresFollowUp() {
      return this.selectedAction.followUp && this.selectedAction.followUp !== 'none';
    },
    requiresMultipleFollowUps() {
      return this.requiresFollowUp && this.details.vouchers.length > 1;
    },
    submitPayloadAsFormData() {
      return (
        !!this.attachment ||
        ['voucher_upload', 'credit_advice'].includes(this.selectedAction.followUp)
      );
    }
  },

  watch: {
    followUpComponent() {
      if (!this.followUpComponent) {
        this.currentVoucherIndex = 0;
        this.followUpPayload = {};
        this.followUpProps = {};
      }
    },
    selectedAction() {
      this.$refs.form?.reset();
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchWorkQueue', 'sendWorkQueueAnswer']),

    async setFollowUpComponent() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      switch (this.selectedAction.followUp) {
        case FollowUpAction.CREDIT_ADVICE:
          this.followUpComponent = CreditDialog;
          break;
        case FollowUpAction.DIRECT_PAYMENT:
          this.followUpComponent = DirectPaymentDialog;
          break;
        case FollowUpAction.ABANDONMENT:
          this.followUpComponent = AbandonmentDialog;
          break;
        case FollowUpAction.ANNULATION:
          this.followUpComponent = AnnulationDialog;
          break;
        case FollowUpAction.EXPOSURE_TYPE:
          this.followUpComponent = ExposureTypeDialog;
          this.followUpProps = { debtor: this.details.debtor };
          break;
        case FollowUpAction.VOUCHER_UPLOAD:
          this.followUpComponent = SubsequentVoucherUploadDialog;
          this.followUpProps = { debtor: this.details.debtor };
          break;
        default:
          this.followUpComponent = ConfirmSubmitDialog;
      }
    },

    addFollowUpPayload(data) {
      if (this.requiresFollowUp && !this.showsConfirmDialog) {
        this.followUpPayload[this.details.vouchers[this.currentVoucherIndex].id] = data;
      }

      // get payload of next next voucher, if necessary
      if (this.requiresFollowUp && this.currentVoucherIndex + 1 < this.details.vouchers.length) {
        this.currentVoucherIndex++;
        return;
      }

      // show confirm dialog after input for multiple vouchers
      if (this.requiresMultipleFollowUps && !this.showsConfirmDialog) {
        this.followUpComponent = ConfirmSubmitDialog;
        return;
      }

      this.submit();
    },

    async submit() {
      this.loading = true;
      const { error } = await this.sendWorkQueueAnswer({
        workQueueIdentifier: this.details.id,
        answerCode: this.selectedAction.code,
        followUpAction: this.selectedAction.followUp ?? 'none',
        payload: this.getPayload()
      });
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
      this.followUpComponent = null;
      this.selectedAction = {};
    },

    getPayload() {
      if (this.submitPayloadAsFormData) {
        let formData = new FormData();

        Object.keys(this.followUpPayload).forEach((voucherId) => {
          const payload = this.followUpPayload[voucherId];

          const payloadEntries = !payload
            ? []
            : payload instanceof FormData
            ? payload.entries()
            : Object.entries(payload);

          for (let keyValuePair of payloadEntries) {
            formData.append(`${voucherId}[${keyValuePair[0]}]`, keyValuePair[1]);
          }
        });

        formData.append('attachmentFile', this.attachment);
        formData.append('note', this.message);
        return formData;
      }

      return { ...this.followUpPayload, note: this.message };
    },

    cancel() {
      if (this.currentVoucherIndex > 0 && this.showsConfirmDialog) {
        this.setFollowUpComponent();
        return;
      }

      if (this.currentVoucherIndex > 0) {
        this.currentVoucherIndex--;
        return;
      }

      this.followUpComponent = null;
    },

    hideAfterSuccess() {
      this.submitted = false;
      this.$emit('success');
      this.resetForm();
    },

    resetForm() {
      this.attachment = null;
      this.message = '';
      this.$refs.form?.reset();
    }
  },

  created() {
    if (!this.details.title) {
      this.error = true;
    }
  }
};
</script>
