<template>
  <div :class="{ preview__container: true, 'mt-6': !isMobile }">
    <v-row v-if="details.vouchers.length > 1" class="preview__switch">
      <v-col cols="auto">
        <app-icon-btn
          :disabled="shownPdfIndex === 0"
          icon="mdi-chevron-left"
          @click="decreaseShownPdfIndex"
        />
      </v-col>
      <v-col cols="auto">
        {{ details.vouchers[shownPdfIndex].type | formatVoucherType }}
        {{ details.vouchers[shownPdfIndex].customNumber }}
      </v-col>
      <v-col cols="auto">
        <app-icon-btn
          :disabled="shownPdfIndex === details.vouchers.length - 1"
          icon="mdi-chevron-right"
          @click="increaseShownPdfIndex"
        />
      </v-col>
    </v-row>
    <voucher-pdf-preview :voucher="details.vouchers[shownPdfIndex]" class="flex-grow-1" />
  </div>
</template>

<script>
import VoucherPdfPreview from '@/shared/components/VoucherPdfPreview';

export default {
  name: 'VoucherListPdfPreview',

  components: { VoucherPdfPreview },

  props: {
    details: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    shownPdfIndex: 0
  }),

  watch: {
    'details.id'() {
      this.shownPdfIndex = 0;
    }
  },

  methods: {
    decreaseShownPdfIndex() {
      if (this.shownPdfIndex === 0) {
        return;
      }
      this.shownPdfIndex--;
    },

    increaseShownPdfIndex() {
      if (this.shownPdfIndex === this.details.vouchers.length - 1) {
        return;
      }
      this.shownPdfIndex++;
    }
  }
};
</script>

<style scoped>
.preview__container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.5rem;
  max-width: min(600px, 100%);
}
.preview__switch {
  flex-grow: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}
</style>
