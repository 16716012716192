var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-list',{attrs:{"list":_vm.queue,"header":"workQueue.title"},scopedSlots:_vm._u([{key:"icon",fn:function(ref){
var item = ref.item;
return [(item.isDone)?_c('icon-check'):_c('icon-dot')]}},{key:"overline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.debtor.name)+" ")]}},{key:"title",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{ 'list-title--text': !item.showError && !item.showWarning }},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"subtitle",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getCustomNumbers(item.vouchers))+" ")]}},{key:"date",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex-fill"},[_vm._v(_vm._s(_vm._f("formatDateTime")(item.updated)))])]}},(_vm.isMobile)?{key:"details",fn:function(){return [_vm._t("details")]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }