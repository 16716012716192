<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-model="open"
      :primary-action-text="getPrimaryActionText('upload.upload')"
      secondary-action-text="navigation.cancel"
      title="uploadInvoice"
      text="vouchers.subsequentVoucherUpload.info"
      :disabled="formIncomplete || failed"
      :loading="loading"
      @submit="submit"
    >
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData"></slot>
      </template>

      <v-container class="px-0">
        <v-row v-if="debtor" dense class="mt-4">
          <v-col cols="12" sm="4">{{ $t('debtor') }}:</v-col>
          <v-col cols="12" sm="8" class="semi-bold">{{ debtor.name }}</v-col>
        </v-row>

        <v-row v-if="voucher" dense class="mt-6" justify="end">
          <v-col cols="12" sm="4">{{ $t('voucher') }}:</v-col>
          <v-col cols="12" sm="8">
            <div class="semi-bold">
              {{ voucher.type | formatVoucherType }}
              {{ voucher.numberToShow }}
            </div>
            <div class="font-weight-regular">
              {{ $t('worth') }}
              {{ voucher.amount | formatCurrency }}
            </div>
          </v-col>
        </v-row>

        <v-row align="center" class="mt-6">
          <v-col cols="12">
            <app-file-input
              v-model="file"
              :name="$t('voucher')"
              placeholder="choosePDF"
              prepend-inner-icon
              align-right
              required
              rules="ext:pdf"
            />
          </v-col>
        </v-row>
      </v-container>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVoucher"
      title="vouchers.subsequentVoucherUpload.confirmed"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <p>{{ $t('vouchers.subsequentVoucherUpload.voucherReceived') }}</p>
    </app-dialog>
  </ValidationObserver>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import WorkQueueFollowUpMixin from '@/mixins/WorkQueueFollowUpMixin';

export default {
  name: 'SubsequentVoucherUploadDialog',

  mixins: [ShowDialogMixin, WorkQueueFollowUpMixin],

  props: {
    debtor: {
      type: Object,
      default: null
    },
    voucher: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    file: null
  }),

  computed: {
    formIncomplete() {
      return !this.file;
    }
  },

  methods: {
    async submit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('fileName', this.file.name);

      if (this.emitData) {
        this.$emit('submit', formData);
        return;
      }

      this.file = null;
      this.$refs.form?.reset();
    }
  },

  created() {
    if (!this.prefillData) {
      return;
    }

    for (let element of this.prefillData.entries()) {
      this[element[0]] = element[1];
    }
  }
};
</script>
