import { DEBTOR_TYPES } from '@/modules/Debtors/statics/debtorType';
import { EXPOSURE_TYPES } from '@/statics/exposureTypes';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/User/store';

export default {
  data: () => ({
    debtorType: '',
    exposureType: '',
    defaultExposureType: '',
    setExposureTypeAsDefault: false
  }),

  computed: {
    debtorTypeItems() {
      return DEBTOR_TYPES.map((type) => ({
        label: this.$t(`debtors.debtorTypes.${type}`),
        value: type
      }));
    },
    exposureTypeItems() {
      return EXPOSURE_TYPES.map((type) => ({
        label: type.translation,
        value: type.code
      }));
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchDefaultExposureType', 'setDefaultExposureType']),

    async getDefaultExposureType() {
      const { defaultExposureType } = await this.fetchDefaultExposureType();

      this.defaultExposureType = defaultExposureType;
      this.exposureType = this.defaultExposureType;
    },

    async submitDefaultExposureType() {
      if (this.exposureType !== this.defaultExposureType && this.setExposureTypeAsDefault) {
        await this.setDefaultExposureType(this.exposureType);
      }
    }
  }
};
